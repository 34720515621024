<template>
  <div id="dashboard-lom-president">
    <v-container fluid>
      <v-row wrap>
        <v-col cols="12" md="12">
          <v-card :color="rows.TableStyle.Color6" :style="rows.TableStyle.Style6">
            <v-card-title>
              <h3>JCI India Calendar</h3>
            </v-card-title>
            <v-card-subtitle></v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="fill-height" v-if="CalenderLoadingFlag">
                <v-col cols="12" md="12" align="center">
                  <div class="d-flex justify-content-center mb-3">
                    <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
                  </div>
                </v-col>
              </v-row>
              <v-row class="fill-height" v-else>
                <v-col>
                  <v-sheet height="64">
                    <v-toolbar flat>
                      <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">Today</v-btn>
                      <v-btn fab text small color="grey darken-2" @click="prev">
                        <v-icon small>mdi-chevron-left</v-icon>
                      </v-btn>
                      <v-btn fab text small color="grey darken-2" @click="next">
                        <v-icon small>mdi-chevron-right</v-icon>
                      </v-btn>
                      <v-toolbar-title>{{ CalenderOptions.MonthName }}</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-menu bottom right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                            <span>{{ typeToLabel[type] }}</span>
                            <v-icon right>mdi-menu-down</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="type = 'day'">
                            <v-list-item-title>Day</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="type = 'week'">
                            <v-list-item-title>Week</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="type = 'month'">
                            <v-list-item-title>Month</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="type = '4day'">
                            <v-list-item-title>4 days</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-toolbar>
                  </v-sheet>
                  <v-sheet height="600">
                    <v-calendar
                      ref="calendar"
                      v-model="focus"
                      color="primary"
                      :events="events"
                      :event-color="getEventColor"
                      :type="type"
                      @click:event="showEvent"
                      @click:more="viewDay"
                      @click:date="viewDay"
                      @change="updateRange"
                    ></v-calendar>
                    <v-menu
                      v-model="selectedOpen"
                      :close-on-content-click="false"
                      :activator="selectedElement"
                      offset-x
                    >
                      <v-card color="grey lighten-4" min-width="350px" flat>
                        <v-toolbar :color="selectedEvent.color" dark>
                          <v-btn icon dark @click="selectedOpen = false">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                          <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                          <v-spacer></v-spacer>
                          <!-- <v-btn icon>
                              <v-icon>mdi-heart</v-icon>
                            </v-btn>
                            <v-btn icon>
                              <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>-->
                        </v-toolbar>
                        <v-card-text>
                          <p>{{selectedEvent.description}}</p>
                          <p>
                            Event Start Date : {{selectedEvent.startTxt}}
                            <br />
                            Event End date : {{selectedEvent.endTxt}}
                          </p>
                        </v-card-text>
                        <v-card-actions>
                          <v-row wrap>
                            <v-col align="center" cols="12" md="12">
                              <v-btn
                                text
                                @click="selectedOpen = false"
                                color="blue"
                                class="font-size-h6 px-10 mr-3 white--text"
                              >Ok</v-btn>
                            </v-col>
                          </v-row>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <p></p>
    </v-container>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  name: "DashboardCalender",
  props: {
    rows: {
      required: true
    }
  },
  data() {
    return {
      LoadingFlag: false,
      StartupLoadingFlag: false,
      CalenderLoadingFlag: false,

      CalenderOptions: {},

      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days"
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],

      ZoneCode: "",
      LomCode: "",
      StartDate: "",
      EndDate: ""
    };
  },
  watch: {},
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      console.log("updateRange called");
      console.log("start=" + JSON.stringify(start));
      console.log("end=" + JSON.stringify(end));

      var StartDate = start.date;
      var EndDate = end.date;

      if (StartDate != this.StartDate || EndDate != this.EndDate) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/tenetative-event/lists";
        var upload = {
          UserInterface: 2,
          FromDate: StartDate,
          ToDate: EndDate,
          Zone: this.ZoneCode
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.events = [];
        this.CalenderLoadingFlag = true;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.CalenderLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            thisIns.StartDate = StartDate;
            thisIns.EndDate = EndDate;
            thisIns.CalenderOptions = records.TableOptions;

            if (flag == 1) {
              thisIns.events = records.TableData;

              // thisIns.toast("success", output);
            } else {
              // thisIns.toast("error", output);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.CalenderLoadingFlag = false;
          });
      }
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    }
  },
  beforeMount() {
    var LomId = this.$session.get("LomId");
    LomId = LomId == (null || undefined) ? "" : LomId;
    this.LomCode = LomId;

    var ZoneCode = this.$session.get("ZoneId");
    ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
    console.log("ZoneCode=" + ZoneCode);
    this.ZoneCode = ZoneCode;
  }
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#dashboard-lom-president {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 16px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 15px;
  }
  .animate-button1 {
    display: inline-block;
    margin: 0 0.5rem;
    color: white;

    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 10s; /* don't forget to set a duration! */
  }
  .president-details-table {
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.01px;
    font-weight: 400;
    font-style: normal;
  }
  .lom-details-table {
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.01px;
    font-weight: 400;
    font-style: normal;
  }
}
</style>